<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>PERFIL DE CLIENTE</h4>
        <FormClient
          ref="formClient"
          :disabled="!clients.edit"
          @submitted="$store.dispatch('dental/loadClientFromId', $event)"
        ></FormClient>
      </div>
    </div>
  </div>
</template>

<script>
import FormClient from "../../store-module/clients/Form";
// import { StoreService } from "../../store-module/service";
import { mapState } from "vuex";

export default {
  components: {
    FormClient
  },
  props: {
    client_id: {
      required: true
    }
  },
  computed: {
    ...mapState({
      client: s => s.dental.client,
      clients: s => s.config.store.clients
    })
    // client() {
    //   return this.$store.state.dental.client
    // }
  },
  mounted() {
    this.$refs.formClient.loadReg(this.client);
    // this.$refs.formClient.
  },
  watch: {
    client: {
      handler(to) {
        // console.log(to)
        if (to) {
          this.$refs.formClient.loadReg(JSON.parse(JSON.stringify(to)));
        }
      }
      // inmediate: true
    }
    // client_id: {
    //   handler(to) {
    //     if (to) {
    //       StoreService.getClient(to).then(client => {
    //         this.$refs.formClient.loadReg(client);
    //       });
    //     }
    //   },
    //   immediate: true
    // }
  }
};
</script>

<style>
</style>
